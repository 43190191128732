<template>
  <div class="DateRange">
    <span
      v-if="!noLabel"
      class="filter-label"
    >Date range:</span>
    <el-button-group>
      <el-button
        type="info"
        icon="el-icon-arrow-left"
        size="mini"
        plain
        @click="changeRange(-1)"
      />
      <el-button
        type="info"
        size="mini"
        plain
        @click="$refs.datePicker.$el.click()"
      >
        {{ humanRange(dateRange) }}
        <el-date-picker
          ref="datePicker"
          v-model="dateRange"
          :picker-options="pickerOptions"
          type="daterange"
          range-separator="To"
          :default-time="['00:00:00', '23:59:59']"
          start-placeholder="From date"
          end-placeholder="To date"
          class="date-picker"
          size="mini"
          @input="applyFilter(dateRange)"
        />
      </el-button>
      <el-button
        type="info"
        icon="el-icon-arrow-right"
        size="mini"
        plain
        @click="changeRange(1)"
      />
    </el-button-group>
  </div>
</template>

<script>
import moment from 'moment';
import { humanRange, pickerOptions } from '@timeragent/core';

export default {
  name: 'DateRange',

  props: {
    noLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
    defaultRange: {
      type: Array,
      required: false,
      default: () => [
        moment().startOf('day').toDate(),
        moment().endOf('day').toDate(),
      ],
    },
  },

  data() {
    return {
      pickerOptions,
      dateRange: this.defaultRange,
    };
  },

  methods: {
    humanRange,
    changeRange(direction) {
      const objStart = moment(this.dateRange[0]);
      const objEnd = moment(this.dateRange[1]);
      const rangeDiff = objEnd.diff(objStart, 'days') + 1;
      const daysInMonth = objStart.daysInMonth();
      const monthSelected = rangeDiff === daysInMonth && objStart.isSame(objEnd, 'month');

      document.activeElement.blur();

      if (monthSelected) {
        this.dateRange = [
          objStart.add(direction, 'month').startOf('month').toDate(),
          objEnd.add(direction, 'month').endOf('month').toDate(),
        ];
      } else {
        this.dateRange = [
          objStart.add(rangeDiff * direction, 'days').toDate(),
          objEnd.add(rangeDiff * direction, 'days').toDate(),
        ];
      }

      this.$forceUpdate();
      this.$refs.datePicker.emitInput(this.dateRange);
    },
    applyFilter(data) {
      this.$emit('apply', data.map(d => (d instanceof Date ? d : moment(d).toDate())));
    },
  },
};
</script>

<style scoped>
  /*I am dirty hack, ahahahahaha AHAHAHAHAHA*/
  .date-picker {
    width       : 0;
    height      : 0;
    overflow    : hidden;
    line-height : 0;
    font-size   : 0;
    opacity     : 0;
    margin      : 0;
    padding     : 0;
    border      : none;
    outline     : none;
    position    : absolute;
    left        : 40px;
    bottom      : 0;
  }
  .filter-label {
    margin-right: 10px;
  }
</style>
